import React from 'react';
import '../../../../src/Style.scss';
import { Link } from 'react-router-dom';
import icon from '../../../Assets/images/icons/location.svg' 

const AboutContent = ({bg, doctor, title, data}) => {

    // const secondP = useRef();
    // if(secondP.current.value == ""){
    //     secondP.current.style.display = "none";
    // }

  return (
    <div className="test-details-area pt-100 pb-70">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    <div className="test-details-card">
                        <img src={bg} className='rounded' alt="bg" />
                        <img src={doctor} className="listing-user-details" alt="bg" />
                        <div className="test-details-content">
                            <div className="details-div d-flex">
                                <h3>{title}</h3>
                                <div className="star-list">
                                    <ul>
                                        <li><i className="bx bxs-star"></i></li>
                                        <li><i className="bx bxs-star"></i></li>
                                        <li><i className="bx bxs-star"></i></li>
                                        <li><i className="bx bxs-star"></i></li>
                                        <li><i className="bx bxs-star"></i></li>
                                    </ul>
                                </div>
                            </div>
                            <p>
                                <img src={icon} alt="icon" />
                                402, Signature Business Park, Chembur, Mumbai
                            </p>
                        </div>
                        {data.map((d, index)=>( 
                        <div key={index} className="all-details">
                            <h4>{d.pointName}</h4>
                            <p className="frist-p">{d.pOne} </p>
                            {/* <p ref={secondP}>{d.pTwo}</p> */}
                            <p>{d.pTwo}</p>
                        </div>
                        ))}

                        <div className="all-details">
                            <h4>Rate &amp; Write Reviews</h4>
                            <ul className="massage-list">
                                <li><i className="bx bxs-star"></i></li>
                                <li className="star"><i className="bx bxs-star"></i></li>
                                <li className="star"><i className="bx bxs-star"></i></li>
                                <li className="star"><i className="bx bxs-star"></i></li>
                                <li className="star"><i className="bx bxs-star"></i></li>
                            </ul>
                            <form id="contactForm" className="contact-from">
                                <div className="row">
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" name="name" id="name" className="form-control" placeholder="Your Name" />
                                        </div>
                                    </div>
            
                                    <div className="col-lg-6 col-sm-6">
                                        <div className="form-group">
                                            <input type="email" name="email" id="email" className="form-control" placeholder="Email Address" />
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" id="message" cols="30" rows="8" placeholder="Writing Now"></textarea>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <button type="submit" className="common-btn style1">Submit Your Review</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="details-info">
                        <h5>Contact Info</h5>
                        <ul className="contact-info">
                            <li>
                                <i className="bx bx-phone-call"></i><Link to="tell:">+91 8655 00 6567</Link>
                            </li>
                            <li>
                                <i className="bx bx-envelope"></i><Link to="mailto:">help@acmefertility.com</Link>
                            </li>
                            <li>
                                <i className="bx bxs-location-plus"></i>
                                402, Signature Business Park, Chembur - Mumbai
                            </li>
                            <li>
                                <i className="bx bx-world"></i>
                                <Link to="">www.acmefertility.com</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="details-info">
                        <h5>Opening Hours</h5>
                        <ul className="opening-info">
                            <li>
                                Monday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Tuesday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Wednesday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Thursday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Friday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Saturday <span>10:00 am - 6:00 pm</span>
                            </li>
                            <li>
                                Sunday <span>Close</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutContent