import React, { useRef } from "react";
import { AboutInnerBanner } from "../Components/Common/InnerBanner/AboutInnerBanner";
import AboutInnBanContent from "../Components/Common/InnerBanner/AboutInnBanContent";
import Transition from "../Components/Transition";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import emailjs from '@emailjs/browser';


const Appointment = () => {
  const { image, heading, pageName } = AboutInnBanContent.Appointment;
  const formik = useFormik({
    initialValues: {
      aName: "",
      aEmail: "",
      aPhone: "",
    //   aService: "",
    //   aDepartment: "",
    //   aDoctor: "",
      aDate: "",
      aTime: "",
    },
    validationSchema: Yup.object({
      aName: Yup.string().required("Name is required"),
      aEmail: Yup.string()
        .email("Invalid email address").required("Email is required"),
      aPhone: Yup.string().matches(/^[0-9]{10}$/, "Enter a valid 10-digit phone number").required("Phone number is required"),
    //   aService: Yup.string().required("select the option"),
    //   aDepartment: Yup.string().required("select the option"),
    //   aDoctor: Yup.string().required("Select the option"),
      aDate: Yup.string().required("Date is required"),
      aTime: Yup.string().required("Time is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      resetForm();
      sendEmail();
    }
  });
  const form = useRef();

  const sendEmail = (e) => {
    emailjs
      .sendForm(
        "service_hyj57ko",
        "template_gz4xo5p",
        form.current,
        "-5NiWs3oTZcfLo1q1"
      )
      .then(
        (result) => {
          console.log(result.text);
          Swal.fire({
            title: "Form Submitted Successfully!",
            text: "We will get back to you soon.",
            icon: "success",
          });
        },
        (error) => {
          console.log(error.text);
          Swal.fire({
            title: "Submission Failed!",
            text: "There was an error submitting your form. Please try again later.",
            icon: "error",
          });
        }
      );
  };

  return (
    <>
      <AboutInnerBanner image={image} heading={heading} pageName={pageName} />

      <div className="appointment-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4">
              <div className="apponitment-image"></div>
            </div>
            <div className="col-lg-8 col-md-8">
              <div className="appointment-from">
                <span>Appointment</span>
                <h3>Make An Appointment</h3>
                <form
                  ref={form}
                  onSubmit={formik.handleSubmit}
                  id="contactForm"
                  className="contact-from"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="aName"
                          className="form-control"
                          placeholder="Name"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.aName}
                        />
                      </div>
                      <div className="error">
                        <div className="with-errors">
                          {formik.touched.aName && formik.errors.aName
                            ? formik.errors.aName
                            : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="aEmail"
                          className="form-control"
                          placeholder="Email"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.aEmail}
                        />
                        <div className="error">
                          <div className="with-errors">
                            {formik.touched.aEmail && formik.errors.aEmail
                              ? formik.errors.aEmail
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="number"
                          name="aPhone"
                          className="form-control"
                          placeholder="Phone"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.aPhone}
                        />
                        <div className="error">
                            <div className="with-errors">
                                {formik.touched.aPhone && formik.errors.aPhone ? formik.errors.aPhone : null}
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <select
                        className="form-select"
                        name="aService"
                        aria-label="Default select example"
                      >
                        <option hidden>Service</option>
                        <option value="IVF">IVF</option>
                        <option value="PRP">PRP</option>
                        <option value="IUI">IUI</option>
                      </select>
                    </div>

                    <div className="col-lg-6">
                      <select
                        className="form-select"
                        name="aDepartment"
                        aria-label="Default select example"
                      >
                        <option hidden>Department</option>
                        <option value="2">Fertility</option>
                      </select>
                    </div>

                    <div className="col-lg-6">
                      <select
                        className="form-select"
                        name="aDoctorName"
                        aria-label="Default select example"
                      >
                        <option hidden>Doctor Name</option>
                        <option value="2">Dr. Neelam Bhise</option>
                      </select>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="date"
                          name="aDate"
                          className="form-control ps-3"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.aDate}
                        />
                      </div>
                      <div className="error">
                            <div className="with-errors">
                                {formik.touched.aDate && formik.errors.aDate ? formik.errors.aDate : null}
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                      <div className="form-group">
                        <input
                          type="time"
                          name="aTime"
                          className="form-control"
                          placeholder="Time"
                          onBlur={formik.handleBlur}
                          onChange={formik.handleChange}
                          value={formik.values.aTime}
                        />
                      </div>
                      <div className="error">
                        <div className="with-errors">
                            {formik.touched.aTime && formik.errors.aTime ? formik.errors.aTime : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <button type="submit" className="common-btn style1">
                        Book Now
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transition(Appointment);
