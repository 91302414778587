import React from 'react';
import { motion } from 'framer-motion';
import img from "../../Assets/images/shapes/newslatter-1.png";
import docShape from "../../Assets/images/shapes/doctor-shape-2.png";
import shapeThree from "../../Assets/images/shapes/newsletter-3.png";
import shapeFive from "../../Assets/images/shapes/newsletter-5.png"
import "../../Style.scss";


export const Counter = () => {
  return (
        <div className="funfacts-area bg-color pt-5 pb-70 bg-002B55">
        <div className="funfacts-inner">
            <div className="container">
                <motion.h4
                    initial = {{y:50, opacity: 0}}
                    whileInView={{y:0, opacity: 1}}
                    transition={{ 
                        y:{type: "spring", stiffness: 60}, 
                        opacity: {duration: 0.2},
                        ease: "easeIn",
                        duration: 1
                    }}
                className='success'>Success Rates</motion.h4>
                <div className="row">
                    {data.map((d) =>(

                    <div className="col-lg-2 col-md-2 col-6 col-sm-2">
                        <motion.div 
                            initial = {{y:50, opacity: 0}}
                            whileInView={{y:0, opacity: 1}}
                            transition={{ 
                                y:{type: "spring", stiffness: 60}, 
                                opacity: {duration: 0.2},
                                ease: "easeIn",
                                duration: 1
                            }}
                        
                        className="single-funfacts-box white-color">
                            <h3><span className="odometer">{d.count}</span><span className="sign">+</span></h3>
                            <p>{d.success}</p>
                        </motion.div>
                    </div>

                    ))}
                </div>
            </div>
        </div>
        <div className="funfact-shape">
            <img src={img} className="fun-shape-1" alt="1" />
            <img src={docShape} className="fun-shape-2" alt="2" />
            <img src={shapeThree} className="fun-shape-3" alt="3" />
            <img src={shapeFive} className="fun-shape-4" alt="4" />
            <img src={shapeFive} className="fun-shape-5" alt="5" />
        </div>
    </div>
  )
}


const data = [
    {
        count: "60",
        success: "IVF"
    },
    {
        count: "72",
        success: "ICSI"
    },
    {
        count: "75",
        success: "Egg Donation"
    },
    {
        count: "76",
        success: "Egg Donation"
    },
    {
        count: "80",
        success: "Surrogacy"
    },
    {
        count: "35",
        success: "IUI"
    },
    
]