import React from 'react'
import { Link } from 'react-router-dom'

export const VideoSection = () => {
  return (
    <div>
        <div className="video-area ptb-100">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-6 col-sm-7 col-md-8">
                    <div className="video-content">
                        <span className="star">Find Everything</span>
                        <h3>Best Doctors for You!</h3>
                        <p>Explore ACME Fertility's advanced IVF treatments and compassionate care on our YouTube channel. Learn about our state-of-the-art facilities, patient success stories, and how we can support you on your journey to parenthood.</p>
                        <Link to="/Appointment" className="common-btn style1">
                            Get Appointment <span> <i className='bx bx-plus'></i></span>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-5 col-md-4">
                    <div className="video-icon">
                        <Link className="popup-youtube" to="https://www.youtube.com/@drneelambhisefertilityandm3061" target='_blank'>
                            <i className='bx bx-play' ></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}
