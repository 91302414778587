import React from 'react';
import logo from "../../Assets/images/logo/logo.png"
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <div className="footer-area  bg-F7F7F7">
        <div className="after-footer pt-100 pb-70">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-sm-7 pe-0">
                        <div className="footer-widget">
                            <img src={logo} alt="logo" />
                            <p>Alternatively, we repudiate just indignation and detest those who mislead and degrade, yet conversely</p>
                            <ul className="social-icons">
                                <li>
                                    <Link to="https://www.facebook.com/bestivfcentermumbai" target='_blank'><i className='bx bxl-facebook' ></i></Link>
                                </li>
                                <li>
                                    <Link to="https://www.youtube.com/@drneelambhisefertilityandm3061" target='_blank'><i className='bx bxl-youtube' ></i></Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/acmefertility/?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw%3D%3D" target='_blank'><i className='bx bxl-instagram' ></i></Link>
                                </li>
                                <li>
                                    <Link to="https://www.google.com/search?q=acme+fertility&oq=&gs_lcrp=EgZjaHJvbWUqBggAEEUYOzIGCAAQRRg7MhUIARAuGCcYrwEYxwEYgAQYigUYjgUyBggCEEUYOzISCAMQLhhDGMcBGNEDGIAEGIoFMg0IBBAuGK8BGMcBGIAEMgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEINjczNWoxajeoAgCwAgA&sourceid=chrome&ie=UTF-8" target='_blank'><i className='bx bxl-google' ></i></Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-5 pe-2">
                        <div className="footer-widget">
                            <h4>About Us</h4>
                            <ul className="footer-list">
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to="/Introduction">Introduction</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to="/QualityExpertise">Quality Expertise</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to="/PersonalizedCare">Personalised Care</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to="/AdvancedTechnology">Advanced Technology</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to="/AffordableTreatment">Affordable Treatments</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to="/RightEnvironment">Right Environment</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-5">
                        <div className="footer-widget">
                            <h4>Our Services</h4>
                            <ul className="footer-list">
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to='/LaparoscopyHysteroscopy'>Laparoscopy & Hysteroscopy</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to='/WomenHealthCare'>Women Health Care</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to='/FertilityCounselling'>Fertility Counselling</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to='/HormonalAssays'>Hormonal Assays</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to='/FollicularMonitoring'>Follicular Monitoring</Link>
                                </li>
                                <li>
                                    <i className='bx bx-chevron-right'></i><Link to='/MaleInfertility'>Male Infertility</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-7">
                        <div className="footer-widget">
                            <h4>Get In Touch</h4>
                            <ul className="footer-information">
                                <li>
                                    <i className='bx bx-phone-call'></i>+91 8655 00 6567
                                </li>
                                <li>
                                    <i className='bx bx-envelope'></i><span className="__cf_email__">help@acmefertility.com</span>
                                </li>
                                <li>
                                    <i className='bx bxs-location-plus'></i> 402, 4th Floor, Signature Business Park, Postal Colony Road, Near Chembur Mono Rail Station, Chembur, Mumbai - 400071
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="footer-text">
            <div className="container">
                <p>
                    © <span>ACME FERTILITY.</span> All Rights Reserved.
                </p>
            </div>
        </div>
    </div>
  )
}
