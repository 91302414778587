import React from 'react'

import img1 from "../../Assets/images/doctor/DrNeelam1.png";
// import img2 from "../../Assets/images/banner/banner-image-2.png";
// import img3 from "../../Assets/images/banner/113.png";
import { Link } from 'react-router-dom';

const Homepage1 = () => {
  return (
    <div className="banner-area">
        <div className="container-fluid px-5">
            <div className="row align-items-center">                                                                                    
                <div className="col-lg-7">
                    <div className="banner-content">
                        <h1>Grow your family, Grow your hope</h1>
                        <p>ACME FERTILITY, Mumbai's leading gynecologist hospital, offers personalized care with the latest medical advancements. Our mission is to provide minimal intervention while delivering top-tier treatments for parenthood. From conception to postnatal, we ensure compassionate care and individualized success.</p>
                        <div class="single-button">
                            <Link to="/Appointment" class="common-btn style1">
                                Book Appointment<span><i class="bx bx-plus"></i></span>
                            </Link>
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="banner-image">
                        <img src={img1} alt="1" />
                        {/* <img src={img2} className="banner-image-2" alt="image" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true" />
                        <img src={img3} className="banner-image-3" alt="image" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true" /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Homepage1