import React from 'react';
import img from '../../Assets/images/doctor/doctor-profile-pic.png'
import icon from '../../Assets/images/icons/check2.png'
const WhyChooseUs = () => {
  return (
    <div className="featured-area pt-100 pb-70">
      <div className="container">
        <div className="section-title mb-5">
          <span>Leading Care</span>
          <h2>Why Choose Us</h2>
        </div>
        <div className='row'>
            <div className="col-lg-4 mt-5">
                <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='1' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>15000+ Pregnancy Happy Clients Through IVF</h3>                               
                    </div>
                </div>
                <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='2' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>5000+ Babies Delivered</h3>                               
                    </div>
                </div>
                <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='3' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>Chief IVF Fertility Specialist</h3>                               
                    </div>
                </div>
                <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='4' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>Quality & Expertise</h3>                               
                    </div>
                </div>
            </div>
            <div className="col-lg-4 d-flex justify-content-center">
                <div className='centerImg'>
                    <img src={img} alt="doctor"  />
                </div>
            </div>
            <div className="col-lg-4 mt-5">
            <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='icon' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>14+ Years of Experience</h3>                               
                    </div>
                </div>
                <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='icon' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>Medical Educator</h3>                               
                    </div>
                </div>
                <div className='points row'>
                    <div className='icon col-lg-3'>
                    <img src={icon} alt='icon' className='checkIcon'/>
                    </div>
                    <div className='text col-lg-9'>
                        <h3>Patient Successfully Treated Across 60+ Countries</h3>                               
                    </div>
                </div>
                
            </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs