import React, { useRef } from "react";
import "../../Style.scss";
import logo from "../../Assets/images/logo/logo.png";
import { Link, NavLink, useLocation } from "react-router-dom";

export const Navbar = () => {
    const location = useLocation();
    const about = useRef();
    const service = useRef();

    const AddActive = () =>{
        if (about.current) {
            about.current.style.color = "#05BB8F";
        }
        
    }
    const ActiveService = () =>{
        if (service.current) {
            service.current.style.color = "#05BB8F";
        }
    }
    const RemoveActive = () =>{
        if (about.current) {
            about.current.style.color = "black";
        }
    }
    const RemoveService = () =>{
        if (service.current) {
            service.current.style.color = "black";
        }
    }

    const isAboutActive = ["/Introduction", "/QualityExpertise", "/PersonalizedCare", "/AdvancedTechnology", "/AffordableTreatment", "/RightEnvironment"].includes(location.pathname);
    const isServiceActive = ["/LaparoscopyHysteroscopy", "/WomenHealthCare", "/FertilityCounselling", "/HormonalAssays", "/FollicularMonitoring", "/MaleInfertility", "/ObstetricCare", "/UrinaryIncontinenceManagement"].includes(location.pathname);
  return (
    <div>
      <div className="navbar-area">
        <div className="mobile-responsive-nav">
          <div className="container">
            <div className="mobile-responsive-menu">
              <div className="logo">
                <Link to="/" >
                  <img src={logo} className="main-logo" alt="logo" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="desktop-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link to="/" className="navbar-brand">
                <img src={logo} className="main-logo" alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <div className="dot-menu"></div>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo02"
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link" >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <Link ref={about} className={`nav-link dropdown-toggle ${isAboutActive ? AddActive() : RemoveActive() }`} >About</Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink to="/Introduction" className="nav-link">
                          Introduction
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/QualityExpertise" className="nav-link">
                          Quality Expertise
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/PersonalizedCare" className="nav-link">
                          Personalised Care
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/AdvancedTechnology" className="nav-link">
                          Advanced Technology
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <Link to="/AffordableTreatment" className="nav-link">
                          Affordable Treatments
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link to="/RightEnvironment" className="nav-link">
                          Right Environment
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <NavLink to="/Programe" className="nav-link">
                      Programe
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <Link ref={service} className={`nav-link dropdown-toggle ${isServiceActive ? ActiveService() : RemoveService() }`}>
                      Services
                    </Link>
                    <ul className="dropdown-menu">
                      <li className="nav-item">
                        <NavLink
                          to="/LaparoscopyHysteroscopy"
                          className="nav-link"
                        >
                          Laparoscopy & Hysteroscopy
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/WomenHealthCare" className="nav-link">
                          Women Health Care
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/FertilityCounselling" className="nav-link">
                          Fertility Counselling
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/HormonalAssays" className="nav-link">
                          Hormonal Assays
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/FollicularMonitoring" className="nav-link">
                          Follicular Monitoring
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/MaleInfertility" className="nav-link">
                          Male Infertility
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/ObstetricCare" className="nav-link">
                          Obstetric Care
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          to="/UrinaryIncontinenceManagement"
                          className="nav-link"
                        >
                          Urinary Incontinence Management
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/Doctor" className="nav-link">
                      Doctors
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink to="/FAQ" className="nav-link">
                      FAQ
                    </NavLink>
                  </li>
                </ul>

                <div className="others-options ms-5">
                  <Link to="/Contact" className="common-btn style1">
                    Contact
                    <span>
                      {" "}
                      <i className="bx bx-plus"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>

        {/* <div className="others-option-for-responsive">
            <div className="container">
                <div className="dot-menu">
                    <div className="inner">
                        <div className="circle circle-one"></div>
                        <div className="circle circle-two"></div>
                        <div className="circle circle-three"></div>
                    </div>
                </div>

                <div className="container">
                    <div className="option-inner">
                        <div className="others-options d-flex">
                            <a href="login-register.html" className="login-text">
                                LOG IN<span></span>
                            </a>
                            <a href="doctor-profile.html" className="common-btn style1">
                                Add Listing<span> <i className='bx bx-plus'></i></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
      </div>
    </div>
  );
};
