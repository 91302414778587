import React from 'react'
import AboutInnBanContent from '../Components/Common/InnerBanner/AboutInnBanContent'
import { AboutInnerBanner } from '../Components/Common/InnerBanner/AboutInnerBanner';
import FAQContent from '../Components/FAQ/FAQContent';
import Transition from '../Components/Transition';
import Homepage1 from '../Components/Homepage/Homepage1';

const FAQ = () => {
    const {image, heading, pageName} = AboutInnBanContent.FAQ;

  return (
    <>
    <AboutInnerBanner image={image} heading={heading} pageName={pageName} />
    
    <FAQContent />

    <Homepage1/>
    </>
  )
}

export default Transition(FAQ) 