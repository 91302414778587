import React from 'react'
import YouTube from './YouTube'

const GoogleReviews = () => {
  return (
    <div className="testimonial-area pt-100 pb-80 px-5" style={{background: "#f3f5f4"}}>
    <div className="container">
        <div className="section-title">
            <span>Our Testimonials</span>
            <h2>What Our Clients Say</h2>
        </div>
        <div className="row">
            <div className="col-lg-6 pb-4">
            <iframe src='https://widgets.sociablekit.com/google-reviews/iframe/25440110' title='client' frameborder='0' width='100%' height='600'></iframe>
            {/* <div class='sk-ww-google-reviews' data-embed-id='25440148'></div><script src='https://widgets.sociablekit.com/google-reviews/widget.js' async defer></script> */}
            </div>
            <div className="col-lg-6 pb-4" style={{height: "600px", overflowY: "scroll"}}>
            <YouTube/>

            </div>
        </div>
    </div>
</div>
  )
}

export default GoogleReviews