import React from 'react'
import { motion } from 'framer-motion';
import img1 from "../../Assets/images/doctor/neelamMaam.png";
// import img2 from "../../Assets/images/banner/banner-image-2.png";
// import img3 from "../../Assets/images/banner/113.png";
import { Link } from 'react-router-dom';

export const Homepage = () => {
  return (
    <div className="banner-area">
        <div className="container-fluid px-5">
            <div className="row align-items-center">                                                                                    
                <div className="col-lg-7">
                    <div className="banner-content">
                        <motion.h1
                            initial = {{x:-100, opacity: 0}}
                            animate ={{x:0, opacity: 1}}
                            transition={{ 
                                x:{type: "spring", stiffness: 60}, 
                                opacity: {duration: 1},
                                ease: "easeIn",
                                duration: 1
                            }}
                        >Grow your family, Grow your hope</motion.h1>
                        <motion.p
                            initial = {{x:-100, opacity: 0}}
                            animate ={{x:0, opacity: 1}}
                            transition={{delay: 0.2, 
                                x:{type: "spring", stiffness: 60}, 
                                opacity: {duration: 1},
                                ease: "easeIn",
                                duration: 1
                            }}
                        >ACME FERTILITY, Mumbai's leading gynecologist hospital, offers personalized care with the latest medical advancements. Our mission is to provide minimal intervention while delivering top-tier treatments for parenthood. From conception to postnatal, we ensure compassionate care and individualized success.</motion.p>
                        <motion.div
                            initial = {{x:-100, opacity: 0}}
                            animate ={{x:0, opacity: 1}}
                            transition={{delay: 0.4, 
                                x:{type: "spring", stiffness: 60}, 
                                opacity: {duration: 1},
                                ease: "easeIn",
                                duration: 1
                            }}
                        class="single-button">
                            <Link to="/Appointment" class="common-btn style1">
                                Book Appointment<span><i class="bx bx-plus"></i></span>
                            </Link>
                        </motion.div>
                        
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="banner-image">
                        <motion.img
                            initial = {{x:100, opacity: 0}}
                            animate ={{x:0, opacity: 1}}
                            transition={{delay: 1, 
                                x:{type: "spring", stiffness: 60}, 
                                opacity: {duration: 1},
                                ease: "easeIn",
                                duration: 2
                            }}
                        src={img1} alt="1" />
                        {/* <img src={img2} className="banner-image-2" alt="image" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true" />
                        <img src={img3} className="banner-image-3" alt="image" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="200" data-aos-once="true" /> */}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
